import React, { useState, useEffect } from "react";
import "./CartModal.css";
import { useCart } from "../context/CartContext";
import { isMobile, isTablet } from "react-device-detect";

function CartModal({ isOpen, onClose }) {
    const [isCheckoutStage, setIsCheckoutStage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSecondStage, setIsSecondStage] = useState(false);
    const [orderType, setOrderType] = React.useState("");
    const [isEatInStage, setIsEatInStage] = useState(false);
    const [isPickupStage, setIsPickupStage] = useState(false);
    const [isPickupFormStage, setIsPickupFormStage] = useState(false);
    const [tableNumber, setTableNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [orderNumber, setOrderNumber] = useState(null);
    const [isOrderSuccess, setIsOrderSuccess] = useState(false);
    const [isEatinOrderSuccess, setIsEatinOrderSuccess] = useState(false);
    const [isNotMobile, setIsNotMobile] = useState(false);


    useEffect(() => {
        if (!isOpen) {
            setIsCheckoutStage(false);
            setIsPickupStage(false);
            setIsEatInStage(false);
            setIsSecondStage(false);
            setIsEatinOrderSuccess(false);
            setIsOrderSuccess(false);
            setIsNotMobile(false);
            setIsPickupFormStage(false);
            setOrderType("");
            setTableNumber("");
            setCustomerName("");
        }
    }, [isOpen]);

    useEffect(() => {
        if (isCheckoutStage) {
            setFormData({
                name: "",
                email: "",
                phone: "",
            });
        }
    }, [isCheckoutStage]);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });


    const handleOrderTypeChange = (event) => {
        setOrderType(event.target.value);
    };

    const { cartItems, removeItemFromCart, updateCartItemQuantity, clearCart } =
        useCart();

    if (!isOpen) return null;
    // Check if the cart is empty
    const isCartEmpty =
        cartItems.length === 0 ||
        cartItems.reduce((total, item) => total + item.quantity, 0) === 0;
    // Calculate the grand total of all items in the cart
    const calculateGrandTotal = () => {
        return cartItems
            .reduce((acc, cartItem) => acc + cartItem.totalPrice, 0)
            .toFixed(2);
    };

    const handleQuantityChange = (itemId, change) => {
        const cartItem = cartItems.find((item) => item.item.id === itemId);
        const newQuantity = cartItem.quantity + change;

        // Ensure quantity does not drop below 1
        if (newQuantity > 0) {
            updateCartItemQuantity(itemId, newQuantity);
        }
    };

    const formatOrderDetails = (cartItems) => {
        let orderMessage = "My Order:\n\n";
        let grandTotal = 0;

        cartItems.forEach((cartItem, index) => {
            const {
                item,
                quantity,
                selectedRequiredOption,
                selectedOptionalOptions,
                totalPrice,
            } = cartItem;
            orderMessage += `Item ${index + 1}:\n`;
            if (parseFloat(item.price) > 0) {
                orderMessage += `${item.item_name}: £${parseFloat(item.price).toFixed(2)}\n`;
            } else {
                orderMessage += `${item.item_name}\n`;
            }


            if (selectedRequiredOption) {
                orderMessage += `${selectedRequiredOption.option_name}: £${parseFloat(
                    selectedRequiredOption.option_price
                ).toFixed(2)}\n`;
            }

            if (selectedOptionalOptions.length > 0) {
                selectedOptionalOptions.forEach((option) => {
                    orderMessage += `${option.option_name}: £${parseFloat(
                        option.option_price
                    ).toFixed(2)}\n`;
                });
            }

            orderMessage += `Quantity: ${quantity}\n`;
            orderMessage += `Price: £${parseFloat(totalPrice).toFixed(2)}\n\n`;

            grandTotal += totalPrice;
        });

        orderMessage += `Total Order Price: £${grandTotal.toFixed(2)}`;
        return { orderMessage, grandTotal };
    };

    function isMobileDevice() {
        const isMobileWidth = window.innerWidth <= 768;
        const isMobileUserAgent = isMobile || isTablet;
        return isMobileWidth || isMobileUserAgent;
    }

    const handleCheckout = () => {
        setIsSecondStage(true);
        setOrderType("");
    };

    const confirmCheckout = async (e) => {
        e.preventDefault();
    
        if (isMobileDevice()) {
            const { orderMessage } = formatOrderDetails(cartItems);
    
            const orderMessageWithDetails = `*Order Type: Pick Up*\n\nCustomer Name: ${customerName}\n\n${orderMessage}`;
    
            const whatsappUrl = `https://wa.me/447368325136?text=${encodeURIComponent(orderMessageWithDetails)}`;
    
            window.open(whatsappUrl, "_blank");
    
            clearCart();
            onClose();
        } else {
            setIsNotMobile(true);
            setIsPickupStage(false);
            setIsPickupFormStage(false);
            setIsSecondStage(false);
            setIsCheckoutStage(false);
            setIsEatInStage(false);
        }
    };
    
    const confirmEatinCheckout = async (e) => {
        e.preventDefault();
    
        if (isMobileDevice()) {
            const { orderMessage } = formatOrderDetails(cartItems);
    
            const orderMessageWithTableNumberAndName = `Table Number: ${tableNumber}\n\nCustomer Name: ${customerName}\n\n${orderMessage}`;
    
            const orderMessageWithDetails = `*Order Type: Eat In*\n\n${orderMessageWithTableNumberAndName}`;
    
            const whatsappUrl = `https://wa.me/447368325136?text=${encodeURIComponent(orderMessageWithDetails)}`;
    
            window.open(whatsappUrl, "_blank");
    
            clearCart();
            onClose();
        } else {
            setIsNotMobile(true);
        }
    };    

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return;
        setIsLoading(true);

        const { name, email, phone } = formData;
        const { orderMessage, grandTotal } = formatOrderDetails(cartItems);

        try {
            const orderResponse = await fetch("/php/create-order.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ totalPrice: grandTotal }),
            });

            const orderResult = await orderResponse.json();

            if (!orderResult.success) {
                throw new Error("Order creation failed.");
            }

            const generatedOrderNumber = orderResult.orderNumber;

            setOrderNumber(generatedOrderNumber);

            const emailResponse = await fetch("/php/send-email.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    name,
                    email,
                    phone,
                    message: `Order Number: ${generatedOrderNumber}\n\n${orderMessage}`,
                }),
            });

            const emailResult = await emailResponse.json();

            if (emailResult.success) {
                clearCart();
                setIsCheckoutStage(false);
                setIsLoading(false);
                setIsOrderSuccess(true);
            } else {
                throw new Error("Email sending failed.");
            }
        } catch (error) {
            console.error("Error:", error.message);
            alert(error.message || "Something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );

    return (
        <div className="cart-modal-overlay">
            <div className="cart-modal-content">
                {isLoading && <SpinnerOverlay />}
                <div className="cart-modal-top">
                    <h2>Your Cart</h2>
                    <button className="close-modal-button" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                {!isCheckoutStage &&
                    !isSecondStage &&
                    !isPickupStage &&
                    !isEatInStage &&
                    !isNotMobile &&
                    !isPickupFormStage &&
                    cartItems.length > 0 && (
                        <button className="clear-cart-button" onClick={clearCart}>
                            Clear Cart
                        </button>
                    )}
                {isNotMobile ? (
                    // Original Success Message (Stage 4)
                    <div className="order-success">
                        <h3>Please use a device with WhatsApp or order in store</h3>
                    </div>
                ) : isOrderSuccess ? (
                    // Original Success Message (Stage 4)
                    <div className="order-success">
                        <i className="fas fa-check-circle"></i>
                        <h3>Your order has been received and is being processed.</h3>
                        {orderNumber && <p>Your order number is: <strong>{orderNumber}</strong></p>}
                        <p>Please check your email for details.</p>
                    </div>
                ) : isEatinOrderSuccess ? (
                    // New Success Message without Email (Stage 5)
                    <div className="order-success">
                        <i className="fas fa-check-circle"></i>
                        <h3>Your order has been received and is being processed.</h3>
                        {orderNumber && <p>Your order number is <strong>{orderNumber}</strong></p>}
                    </div>
                ) : isPickupStage ? (
                    <>
                        {/* Pickup Address (Stage 2) */}
                        <div className="pickup-stage">
                            <div className="pickup-top">
                                <i
                                    onClick={() => {
                                        setIsPickupStage(false);
                                        setIsSecondStage(true);
                                    }}
                                    className="fas fa-arrow-left"
                                ></i>

                                <h3>Pickup Address</h3>
                            </div>
                            <div className="pickup-address">
                                <i className="fas fa-map-marker-alt"></i>
                                <p>
                                    34, Wellington Road, <br></br>
                                    Eccles M30 0NP <br></br>
                                    Gilda Brooks Building
                                </p>
                            </div>
                            <button className="add-to-cart-button" onClick={() => {
                                setIsPickupFormStage(true);
                                setIsPickupStage(false);
                            }}>
                                Continue
                            </button>
                        </div>
                    </>
                ) : isPickupFormStage ? (
                    <>
                        <div className="pickup-top">
                            <i
                                onClick={() => {
                                    setIsPickupStage(true);
                                    setIsPickupFormStage(false);
                                }}
                                className="fas fa-arrow-left"
                            ></i>
                            <h3>Customer Info</h3>
                        </div>
                        <form className="eatin-form" onSubmit={confirmCheckout}>
                            <div className="form-group eat-in-form-group checkout-form">
                                <input
                                    type="text"
                                    id="customerName"
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    required
                                    placeholder="Enter your name"
                                />
                            </div>

                            <button
                                type="submit"
                                className="add-to-cart-button"
                                disabled={!customerName.trim()}
                            >
                                {isLoading ? "Placing Order..." : "Place Order"}
                            </button>
                        </form>
                    </>
                ) : isSecondStage ? (
                    <>
                        {/* Eat-In or Pick-Up Selection (Stage 1) */}
                        <div className="eat-in-pick-up-stage">
                            <div className="pickup-top">
                                <i
                                    onClick={() => {
                                        setIsSecondStage(false);
                                        setIsPickupStage(false);
                                        setIsCheckoutStage(false);
                                        setIsEatInStage(false);
                                        setOrderType("");
                                        setTableNumber("");
                                        setCustomerName("")
                                    }}
                                    className="fas fa-arrow-left"
                                ></i>
                                <h3>Choose Your Option</h3>
                            </div>
                            <div className="radio-options">
                                <div>
                                    <input
                                        type="radio"
                                        id="eatIn"
                                        name="orderType"
                                        value="eat-in"
                                        checked={orderType === "eat-in"}
                                        onChange={handleOrderTypeChange}
                                    />
                                    <label className="radio-label" htmlFor="eatIn">
                                        Eat In
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="pickUp"
                                        name="orderType"
                                        value="pick-up"
                                        checked={orderType === "pick-up"}
                                        onChange={handleOrderTypeChange}
                                    />
                                    <label className="radio-label" htmlFor="pickUp">
                                        Pick Up
                                    </label>
                                </div>
                            </div>
                            <button
                                className="add-to-cart-button"
                                disabled={!orderType}
                                onClick={() => {
                                    if (orderType === "eat-in") {
                                        setIsEatInStage(true);
                                        setIsSecondStage(false);
                                    } else {
                                        setIsPickupStage(true);
                                    }
                                }}
                            >
                                Proceed
                            </button>
                        </div>


                    </>
                ) : isEatInStage ? (
                    <>
                        <div className="eat-in-stage">
                            <div className="pickup-top">
                                <i
                                    onClick={() => {
                                        setIsSecondStage(true);
                                    }}
                                    className="fas fa-arrow-left"
                                ></i>
                                <h3>Customer Info</h3>
                            </div>
                            <form className="eatin-form" onSubmit={confirmEatinCheckout}>
                                {/* Table Number Field */}
                                <div className="form-group eat-in-form-group checkout-form">
                                    <input
                                        type="text"
                                        id="tableNumber"
                                        value={tableNumber}
                                        onChange={(e) => setTableNumber(e.target.value)}
                                        required
                                        placeholder="Enter your table number"
                                    />
                                </div>

                                {/* Name Field */}
                                <div className="form-group eat-in-form-group checkout-form">
                                    <input
                                        type="text"
                                        id="customerName"
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                        required
                                        placeholder="Enter your name"
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="add-to-cart-button"
                                    disabled={!tableNumber.trim() || !customerName.trim()}
                                >
                                    {isLoading ? "Placing Order..." : "Place Order"}
                                </button>
                            </form>
                        </div>
                    </>
                ) : !isCheckoutStage ? (
                    <>
                        {/* Cart View (Stage 1) */}
                        {cartItems.length === 0 ? (
                            <p className="empty-cart">Your cart is empty</p>
                        ) : (
                            <ul className="cart-items-list">
                                {cartItems.map((cartItem, index) => {
                                    const {
                                        item,
                                        quantity,
                                        selectedRequiredOption,
                                        selectedOptionalOptions,
                                        totalPrice,
                                    } = cartItem;
                                    return (
                                        <li key={index} className="cart-item">
                                            <div className="cart-item-main">
                                                {item.image && (
                                                    <img
                                                        src={`/uploads/${item.image}`}
                                                        alt={item.item_name}
                                                        className="cart-item-image"
                                                    />
                                                )}
                                                <div className="cart-item-details">
                                                    <div className="cart-item-details-top">
                                                        <h3>{item.item_name}</h3>
                                                        {item.price > 0 && (
                                                            <p className="menu-item-price">£{parseFloat(item.price).toFixed(2)}</p>
                                                        )}
                                                    </div>

                                                    {selectedRequiredOption && (
                                                        <div className="cart-option">
                                                            <span className="option-name">
                                                                {selectedRequiredOption.option_name}
                                                            </span>
                                                            <span className="option-price">
                                                                £
                                                                {parseFloat(
                                                                    selectedRequiredOption.option_price
                                                                ).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    )}

                                                    {selectedOptionalOptions.length > 0 && (
                                                        <div className="cart-options-list">
                                                            {selectedOptionalOptions.map((option, idx) => (
                                                                <div key={idx} className="cart-option">
                                                                    <span className="option-name">
                                                                        {option.option_name}
                                                                    </span>
                                                                    <span className="option-price">
                                                                        £
                                                                        {parseFloat(option.option_price).toFixed(2)}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}

                                                    <div className="cart-bottom">
                                                        <div className="quantity-controls">
                                                            <button
                                                                className="cart-quantity-btn"
                                                                onClick={() =>
                                                                    handleQuantityChange(item.id, -1)
                                                                }
                                                            >
                                                                <i className="fas fa-minus"></i>
                                                            </button>
                                                            <span>{quantity}</span>
                                                            <button
                                                                className="cart-quantity-btn"
                                                                onClick={() => handleQuantityChange(item.id, 1)}
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </button>
                                                        </div>
                                                        <p className="cart-item-total">
                                                            £{parseFloat(totalPrice).toFixed(2)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cart-item-actions">
                                                <button
                                                    className="delete-btn"
                                                    onClick={() => removeItemFromCart(item.id)}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <div className="checkout-section">
                            <h3>Total: £{calculateGrandTotal()}</h3>
                            <button
                                className="add-to-cart-button"
                                disabled={isCartEmpty}
                                onClick={handleCheckout}
                            >
                                Checkout
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        {/* Checkout Form (Stage 3) */}
                        <div className="checkout-form">
                            <div className="checkout-form-top">
                                <i
                                    onClick={() => setIsPickupStage(true)}
                                    className="fas fa-arrow-left"
                                ></i>
                                <h3>Complete Your Order</h3>
                            </div>
                            <form onSubmit={handleFormSubmit}>
                                <input
                                    type="text"
                                    placeholder="Your Name"
                                    value={formData.name}
                                    onChange={(e) =>
                                        setFormData({ ...formData, name: e.target.value })
                                    }
                                    required
                                />
                                <input
                                    type="email"
                                    placeholder="Your Email"
                                    value={formData.email}
                                    onChange={(e) =>
                                        setFormData({ ...formData, email: e.target.value })
                                    }
                                    required
                                />
                                <input
                                    type="tel"
                                    placeholder="Your Phone Number"
                                    value={formData.phone}
                                    onChange={(e) =>
                                        setFormData({ ...formData, phone: e.target.value })
                                    }
                                    required
                                />
                                <button
                                    type="submit"
                                    className="add-to-cart-button"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Placing Order..." : "Place Order"}
                                </button>
                            </form>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default CartModal;
