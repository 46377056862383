import React, { useState, useEffect } from 'react';
import './OrderPage.css';
import { useCart } from '../context/CartContext';
import ItemModal from '../components/ItemModal';

const OrderPage = () => {
    const [categories, setCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [activeCategories, setActiveCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const { successMessage, clearSuccessMessage } = useCart();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [scrollAfterClick, setScrollAfterClick] = useState(false);
    const [showBackToTop, setShowBackToTop] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchCategories(), fetchMenuItems()]);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (scrollAfterClick && activeCategories.length === 1) {
            scrollToCategory(activeCategories[0]);
            setScrollAfterClick(false);
        }
    }, [scrollAfterClick, activeCategories]);

    useEffect(() => {
        if (showSuccessMessage) {
            const timer = setTimeout(() => {
                clearSuccessMessage();
                setShowSuccessMessage(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessMessage, clearSuccessMessage]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch('/php/categories.php');
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();

            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchMenuItems = async () => {
        try {
            const response = await fetch('/php/menu-items.php');
            if (!response.ok) {
                throw new Error('Failed to fetch menu items');
            }
            const data = await response.json();
            setMenuItems(data);

        } catch (error) {
            console.error('Error fetching menu items:', error);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setActiveCategories([]);
            return;
        }

        const matchingCategories = categories.filter((category) => {
            // Get items under the main category
            const categoryItems = getMenuItemsForCategory(category.id);

            // Check if any item in the main category matches
            const matchesCategory = categoryItems.some(item => item.item_name.toLowerCase().includes(query));

            // Get subcategories
            const subcategories = category.subcategories || [];

            // Check if any item in subcategories matches
            const matchingSubcategories = subcategories.filter(sub => {
                const subcategoryItems = getMenuItemsForCategory(sub.id);
               
                const match = subcategoryItems.some(item => item.item_name.toLowerCase().includes(query));

                return match;
            });

            // If category or any subcategory has a match, show the category
            return matchesCategory || matchingSubcategories.length > 0;
        }).map(category => category.id);

        setActiveCategories(matchingCategories);
    };

    const handleCategoryClick = (categoryId) => {
        toggleAccordion(categoryId);
        setScrollAfterClick(true);
    };

    const toggleAccordion = (categoryId) => {
        setActiveCategories((prevActive) => {
            if (prevActive.includes(categoryId)) {
                return prevActive.filter((id) => id !== categoryId);
            }
            return [categoryId];
        });
    };

    const getMenuItemsForCategory = (categoryId) => {
        const filteredItems = menuItems.filter((item) => Number(item.category_id) === Number(categoryId));

        return filteredItems;
    };

    const categoryIndex = new Map();
    categories.forEach((category) => {
        const itemNames = getMenuItemsForCategory(category.id).map((item) => item.item_name.toLowerCase());
        categoryIndex.set(category.id, itemNames);
    });

    const getFilteredCategories = () => {
        if (!searchQuery) return sortCategories(categories);
    
        const lowerCaseQuery = searchQuery.toLowerCase();
    
        // Create category index dynamically (including subcategories)
        const categoryIndex = new Map();
        
        categories.forEach((category) => {
            let itemNames = getMenuItemsForCategory(category.id).map((item) => item.item_name.toLowerCase());
    
            // Include items from subcategories
            if (category.subcategories) {
                category.subcategories.forEach((sub) => {
                    const subItems = getMenuItemsForCategory(sub.id).map((item) => item.item_name.toLowerCase());
                    itemNames = itemNames.concat(subItems);
                });
            }
    
            categoryIndex.set(category.id, itemNames);
        });
    
        // Filter categories based on search query
        const filteredCategories = categories.filter((category) =>
            categoryIndex.get(category.id)?.some((itemName) => itemName.includes(lowerCaseQuery))
        );
    
        return sortCategories(filteredCategories);
    };
    

    const sortCategories = (categoryList) => {
        return categoryList.sort((a, b) => {
            const startersId = "1";
            const mainMenuId = "17";
            const drinksId = "16";
            const preorderId = "6";
    
            if (a.id === startersId) return -1;
            if (b.id === startersId) return 1;
    
            if (a.id === mainMenuId) return -1;
            if (b.id === mainMenuId) return 1;
    
            if (a.id === drinksId) return 1;
            if (b.id === drinksId) return -1;
    
            if (a.id === preorderId) return 1;
            if (b.id === preorderId) return -1;
    
            return a.category_name.localeCompare(b.category_name); 
        });
    };
    
    const handleAddToCart = (item) => {
        setSelectedItem(item);
    };

    const filteredCategories = getFilteredCategories();

    const NAVBAR_HEIGHT = 100;

    const scrollToCategory = (categoryId) => {
        const categoryElement = document.getElementById(`category-${categoryId}`);
        if (categoryElement) {
            const rect = categoryElement.getBoundingClientRect();
            const topOffset = window.pageYOffset + rect.top - NAVBAR_HEIGHT;
            setTimeout(() => {
                window.scrollTo({ top: topOffset, behavior: 'smooth' });
            }, 100);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="order-page-container">
            {showSuccessMessage && successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}

            <p className="order-notice">
                We are accepting online pick-up orders! Select your food, place your order, make payment by transfer then pick up from our restaurant.
            </p>

            <p className="order-notice allergy-notice">
                For allergies, please contact us.
            </p>

            <input
                type="text"
                className="search-input"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearch}
            />

            {/* Error and loading notices */}
            {isLoading && <p className="no-items">Loading items...</p>}

            {categories.length === 0 && !isLoading && <p className="no-items">No categories found</p>}

            {searchQuery && filteredCategories.length === 0 && (
                <p className="no-items">No results found for "{searchQuery}"</p>
            )}

            <div className="order-accordion">
                {filteredCategories
                    .filter(category => !category.parent_id)
                    .map(category => {
                        const subcategories = category.subcategories || [];
                        const categoryItems = getMenuItemsForCategory(category.id)
                            .filter(item => item.item_name.toLowerCase().includes(searchQuery.toLowerCase())); // 🔍 Apply search filter correctly

                        return (
                            <div key={category.id} id={`category-${category.id}`} className="order-accordion-item">
                                {/* Category Header */}
                                <div
                                    className={`order-accordion-header ${activeCategories.includes(category.id) ? 'active' : ''}`}
                                    onClick={() => handleCategoryClick(category.id)}
                                >
                                    <span className="category-name">{category.category_name}</span>
                                    <span className="accordion-icon">
                                        {activeCategories.includes(category.id) ? '-' : '+'}
                                    </span>
                                </div>

                                {/* Show subcategories or direct menu items */}
                                {activeCategories.includes(category.id) && (
                                    <div className="order-accordion-body">
                                        {/* Check if category and subcategories have items */}
                                        {categoryItems.length === 0 &&
                                            (subcategories.length === 0 || subcategories.every(sub =>
                                                getMenuItemsForCategory(sub.id)
                                                    .filter(item => item.item_name.toLowerCase().includes(searchQuery.toLowerCase()))
                                                    .length === 0
                                            )) ? (
                                            <p className="no-items-message">No items available</p>
                                        ) : (
                                            <>
                                                {/* Render main category items if available */}
                                                {categoryItems.length > 0 && (
                                                    <ul className="order-menu-list">
                                                        {categoryItems
                                                            .sort((a, b) => a.item_name.localeCompare(b.item_name))
                                                            .map(item => (
                                                                <li key={item.id} className="order-menu-item">
                                                                    <img
                                                                        src={`/uploads/${item.image}`}
                                                                        alt={item.item_name}
                                                                        className="order-menu-item-image"
                                                                    />
                                                                    <div className="order-menu-item-details">
                                                                        <h3>{item.item_name}</h3>
                                                                        <p className="menu-item-description">{item.item_description}</p>

                                                                        {Number(item.in_stock) === 0 && (
                                                                            <div className="out-of-stock-ticker">
                                                                                <span>Sold Out</span>
                                                                            </div>
                                                                        )}

                                                                        <div className="menu-item-bottom">
                                                                            {item.price > 0 ? (
                                                                                <p className="menu-item-price">£{item.price}</p>
                                                                            ) : (
                                                                                <div></div>
                                                                            )}
                                                                            <div
                                                                                className="add-to-cart-icon"
                                                                                onClick={() => handleAddToCart(item)}
                                                                            >
                                                                                <i className="fas fa-shopping-cart cart-btn"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}

                                                {/* Render subcategories only if they have matching items */}
                                                {subcategories
                                                    .filter(sub =>
                                                        getMenuItemsForCategory(sub.id).some(item =>
                                                            item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
                                                        )
                                                    )
                                                    .map(sub => {
                                                        const subItems = getMenuItemsForCategory(sub.id)
                                                            .filter(item => item.item_name.toLowerCase().includes(searchQuery.toLowerCase()));

                                                        return (
                                                            <div key={sub.id} className="subcategory">
                                                                <h3 className="subcategory-heading">{sub.category_name}</h3>
                                                                <ul className="order-menu-list">
                                                                    {subItems.map(item => (
                                                                        <li key={item.id} className="order-menu-item">
                                                                            <img
                                                                                src={`/uploads/${item.image}`}
                                                                                alt={item.item_name}
                                                                                className="order-menu-item-image"
                                                                            />
                                                                            <div className="order-menu-item-details">
                                                                                <h3>{item.item_name}</h3>
                                                                                <p className="menu-item-description">{item.item_description}</p>

                                                                                {Number(item.in_stock) === 0 && (
                                                                                    <div className="out-of-stock-ticker">
                                                                                        <span>Sold Out</span>
                                                                                    </div>
                                                                                )}

                                                                                <div className="menu-item-bottom">
                                                                                    {item.price > 0 ? (
                                                                                        <p className="menu-item-price">£{item.price}</p>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )}
                                                                                    <div
                                                                                        className="add-to-cart-icon"
                                                                                        onClick={() => handleAddToCart(item)}
                                                                                    >
                                                                                        <i className="fas fa-shopping-cart cart-btn"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        );
                                                    })}

                                            </>
                                        )}
                                    </div>
                                )}

                                {/* Back to Top Button */}
                                {showBackToTop && (
                                    <button className="back-to-top-btn" onClick={scrollToTop}>
                                        <i className="fas fa-arrow-up"></i>
                                    </button>
                                )}
                            </div>
                        );
                    })}

                {selectedItem && (
                    <ItemModal
                        isOpen={!!selectedItem}
                        item={selectedItem}
                        onClose={() => setSelectedItem(null)}
                        onSuccess={() => setShowSuccessMessage(true)}
                    />
                )}
            </div>

        </div>
    );
}
export default OrderPage;